import bannerImage01 from '../../assets/images/banner01.jpg';
import bannerImage02 from '../../assets/images/banner02.jpg';
import Slider from "react-slick";

const Banner = () => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return(
        <div className='banner-slider-wrapper'>
          <div className='container'>
            <Slider {...settings}>
              <div className='banner-img'>
                <img src={bannerImage01} alt='Banner' />
              </div>
              <div className='banner-img'>
                <img src={bannerImage02} alt='Banner' />
              </div>
            </Slider>
          </div>
        </div>
    );
}

export default Banner;