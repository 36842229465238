import { Link } from "react-router-dom";

const ProductCard = ({item}) => {
    return(
        <div className="col-lg-3 col-md-6">
            <div className="product-items">
                <Link to={`/single-product/${item.id}`}>
                    <div className="product-images">
                        <img src={item.image} alt="img" />
                    </div>
                    <div className="product-meta-data">
                        <h4>{item.title}</h4>
                        <span>${item.price} USD</span>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default ProductCard;