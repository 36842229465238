import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../../../Components/Loading";

const Footer = () => {

    const {data,status} =  useSelector((state)=> state.collections);


    return(
        <footer className="main-footer-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="footer-cl">
                            <h3>Categories</h3>
                            <ul>
                                {
                                    status !== 'idle' 
                                    ? 
                                    <Loading /> 
                                    :                    
                                    data.map((item, index)=>{
                                        return(
                                            <li key={index}><Link to={`/products/category/${item}`}>{item}</Link></li>
                                        )
                                    })    
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;