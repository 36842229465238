import ProductCard from "../../Components/Products/ProductCard";
import Loading from "../../Components/Loading";
import { useDispatch, useSelector } from "react-redux";

import { filterProducts } from '../../features/product/productSlice'
import { useEffect, useState } from "react";

const Shop = () => {

    const {data,status,filter} =  useSelector((state)=> state.products);
    const [search,setSearch] = useState('')
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(filterProducts({search}))
    },[search])
    return(
        <div className="product-wrapper loading-section">
            <div className="container">
                <div className="site-heading">
                    <h2 className="text-center">Shop Products</h2>
                </div>
                <div className="site-search-wrapper">
                    <div className="py-4">
                        <input  type="text" placeholder="Search..." value={search} onChange={(e)=>setSearch(e.target.value)} className="form-control py-2" />
                    </div>
                </div>
                <div className="row">
                    {
                        status !== 'idle' 
                        ? 
                        <Loading /> 
                        :
                        (search && filter.length === 0)
                        ? 
                        <p>Product Not Found</p>
                        : 
                        search
                        ? 
                        filter.map((item, index)=>{
                            return(
                                <ProductCard item={item} index={index} key={index} />
                            )
                        }) 
                        : 
                        data.map((item, index)=>{
                            return(
                                <ProductCard item={item} index={index} key={index} />
                            )
                        })
                    }              
                </div>
            </div>
        </div>
    )
}

export default Shop;