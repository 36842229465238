import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllCollection } from '../../features/collections/collectionAPI';


const initialState = {
    data: [],
    filter: [],
    status: "idle",
    error: null
}

export const fetchCollections = createAsyncThunk(
    "collection/fetchCollection",
    async () => {
        const response = await getAllCollection();
        return response.data;
    }
);

const collectionSlice = createSlice({
    name: "collections",
    initialState,

    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchCollections.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(fetchCollections.fulfilled, (state, action) => {
            state.status = "idle";
            state.data = action.payload;
        })
        .addCase(fetchCollections.rejected, (state, action) => {
            state.status = "idle";
            state.error = action.error?.message || "something went wrong";
        })
    }
});



export default collectionSlice.reducer;