import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  products: localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [],
  total: 0,
  totalItems: 0,
  totalUniqueItems: 0,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const index = state.products.findIndex(
        (item) => item.id === action.payload.id
      );

      // Check if the quantity condition is true
      const desiredQuantity = action.payload.quantity || 1; // You can adjust this as needed
      if (index >= 0) {
        // Product is already in the cart
        state.products[index].quantity += desiredQuantity;
      } else {
        // Product is not in the cart
        state.products.push({ ...action.payload, quantity: desiredQuantity });
      }

      // Update totalItems, totalUniqueItems, and total
      updateCart(state);

      toast.success(`Added ${action.payload.title} to cart.`);
    },
    updateQuantity: (state, action) => {
      const index = state.products.findIndex(
        (item) => item.id === action.payload.id
      );

      if (index >= 0) {
        // Remove item if quantity is less than 1
        if (action.payload.quantity < 1) {
          state.products.splice(index, 1);
          toast.success(`Item removed from cart.`);
        } else {
          // Update quantity if it's greater than or equal to 1
          state.products[index].quantity = action.payload.quantity;
        }
        updateCart(state);
      }
    },

    removeFromCart: (state, action) => {
      const index = state.products.filter(
        (item) => item.id === action.payload.id
      );
      if (index >= 0) {
        state.products.splice(index, 1);
      }
      updateCart(state);
    },
    updateTotal: (state) => {
      // Update totalItems, totalUniqueItems, and total
      state.totalItems = state.products.reduce(
        (total, item) => total + item.quantity,
        0
      );
      state.totalUniqueItems = state.products.length;
      state.total = state.products.reduce(
        (total, item) => total + item.quantity * item.price,
        0
      );
    },
    emptyCart: (state) => {
      state.products = [];
      updateCart(state);
    },
  },
});

const updateCart = (state) => {
  state.totalItems = state.products.reduce(
    (total, item) => total + item.quantity,
    0
  );
  state.totalUniqueItems = state.products.length;
  state.total = state.products.reduce(
    (total, item) => total + item.quantity * item.price,
    0
  );
  localStorage.setItem("cart", JSON.stringify(state.products));
  return state;
};
export const {
  addToCart,
  removeFromCart,
  emptyCart,
  updateTotal,
  updateQuantity,
} = cartSlice.actions;

export const selectCart = (state) => state.products;

export default cartSlice.reducer;