import { useDispatch, useSelector } from "react-redux";
import { removeFromCart, updateQuantity } from '../../features/cart/cartSlice';
import { roundPrice } from '../../utils/functions';
import CartList from "../../Components/CartList";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";

const Cart = () => {

    const [show, setShow] = useState(false);
    const [cartItem, setcartItem] = useState({});

    const {products,total,totalItems} =  useSelector((state)=> state.cart);

    const dispatch = useDispatch();

    const handleClose = () => setShow(false);

    const handleShow = (item) => {
        setcartItem(item);
        setShow(true);
    }

    const handlUpdateQuantity = (id,quantity) => {
        dispatch(updateQuantity({id,quantity}))
    }

    const removecartItem = (id) => {
        dispatch(removeFromCart(id));
        setShow(false);
    }

    return(
        <>
            {/* CONFIRMATION MODAL FOR DELETE CART ITEM  */}

            <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Body>
                    <div className="modal-body-text">Are you sure you want to delete <strong>{`'${cartItem.title?.trim()}'`}</strong> ?</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}><small>No</small></Button>
                    <Button variant="danger" onClick={() => removecartItem(cartItem.id)}><small>Yes</small></Button>
                </Modal.Footer>
            </Modal>

            {/* CART SCREEN  */}

            <div className="cart-screen-wrapper">
                <div className="container">
                    <div className="site-heading">
                        <h2>Cart</h2>
                    </div>

                    {/* CART REPEATED LIST ITEM  */}
                    {
                        products.length > 0
                        ?
                        products.map((item,index)=>{
                            return(
                                <CartList key={index} item={item} index={index} updateQuantity={handlUpdateQuantity} handleShow={handleShow} />
                            )
                        })
                        :
                        <h3 className="text-center pt-5">Your Cart is Empty</h3>
                    }

                    {/* CART TOTAL PRICE AND TOTAL ITEM */}

                    <div className="cart-total-calculation">
                        <div className="cart-total-inner">
                            <h5><span>Total:</span> ${roundPrice(total)}</h5>
                            <h5><span>Total Items:</span> {totalItems}</h5>
                            <div className="text-right">
                                <button className="btn btn-dark">Checkout</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default Cart;