import { Link } from "react-router-dom";

const CollectionCard = ({item, index}) => {
    
    return(
        <div className='col-lg-3 col-md-6' key={index}>
            <div className='grid-item-box'>
                <Link to={`/products/category/${item}`}>
                    <div className="collection-content">
                        <h5>{item}</h5>
                        <span><small>View Products</small></span>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default CollectionCard;