const CartQuantity = ({quantity, setQuantity}) => {

    const incrementQuantity = () => {
        quantity < 30 ? setQuantity(Number(quantity + 1)) : setQuantity(30);
    }

    const decrementQuantity = () => {
        quantity > 1 ? setQuantity(Number(quantity - 1)) : setQuantity(1);
    }

    return(
        <div className="cart-input-wrapper">
            <button onClick={()=>decrementQuantity()}>-</button>
            <span>{quantity}</span>
            <button onClick={()=>incrementQuantity()}>+</button>
        </div>
    );
}

export default CartQuantity;