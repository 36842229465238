import { configureStore } from "@reduxjs/toolkit";
import productReducer from "../features/product/productSlice";
import collectionSlice from "../features/collections/collectionSlice";
import cartSlice from "../features/cart/cartSlice";

const store = configureStore({
    reducer: {
        products: productReducer,
        collections: collectionSlice,
        cart: cartSlice,
    }
});

export default store;