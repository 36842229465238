import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import Routes from './Routes';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchProducts } from './features/product/productSlice'
import { fetchCollections } from './features/collections/collectionSlice'
import { updateTotal } from './features/cart/cartSlice'
import { useEffect } from 'react';

function App() {

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(fetchProducts());
    dispatch(fetchCollections());
    dispatch(updateTotal());
  },[])

  return (
    <>
      <ToastContainer
        position="bottom-right"
      />
      <Routes />
    </>
  );
}

export default App;
