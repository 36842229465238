import { useState, useEffect } from "react";
import {BASE_URL} from '../../utils/Config';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from "../../Components/Loading";
import ProductCard from "../../Components/Products/ProductCard";
import { useParams } from "react-router-dom";

const ProductCollections = () => {

  const [getPrdData, setGetPrdData] = useState([]);
  const [loading, setLoading] = useState(false);

  const CategoryId = useParams();

  // GET PRODUCT FROM API 

  const getPrddata = async () => {

    let PRODUCTS_API_URL = `${BASE_URL}products/category/${CategoryId.id}`;

    setLoading(true);

    try{
      const response = await axios.get(PRODUCTS_API_URL);
      const res = await response?.data || []
      console.log(res);
      setGetPrdData(res);
    }
    catch(err){
      toast.error(err);
    }
    finally{
      setLoading(false)
    }

  }

  useEffect(()=>{
    getPrddata();
  }, [CategoryId]);

  return(
      <div className="product-wrapper loading-section">
        <div className="container">
            <div className="cat-heading">
                <h2>{CategoryId.id} Products</h2>
            </div>
        </div>
        <div className="container">
          <div className="row">
            {
              loading 
              ? 
              <Loading /> 
              :
              getPrdData.map((item, index)=>{
                return(
                  <ProductCard item={item} index={index} key={index} />
                )
              })
            }              
          </div>
        </div>
      </div>
  );
}

export default ProductCollections;