import Loading from "../Loading";
import CollectionCard from "./CollectionCard";
import { useSelector } from "react-redux";

const Collections = () => {

    const {data,status} =  useSelector((state)=> state.collections);

    return(
        <div className="collection-grid-wrapper loading-section">
          <div className='container'>
            <div className="site-heading">
                <h2 className="text-center">Our Collections</h2>
            </div>
            <div className="row">
                {
                    status !== 'idle' 
                    ? 
                    <Loading /> 
                    :                    
                    data.map((item, index)=>{
                        return(
                            <CollectionCard key={index} item={item} index={index} />
                        );
                    })     
                }
            </div>
          </div>
        </div>
    );
}

export default Collections;