import { Outlet } from "react-router-dom";
import Footer from "../Layouts/Includes/Footer";
import Header from "../Layouts/Includes/Header";
import ScrollToTop from "../Components/ScrollToTop";

const PublicRoutes = () => {


    return(
        <div className="main-site-wrapper">
            <ScrollToTop />
            <Header />
            <main className='main-content-wrapper'>
                <Outlet />
            </main>
            <Footer />
        </div>
    );
}

export default PublicRoutes;