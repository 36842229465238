// FORMAT PRICE WITH CURRENCY
const priceFormat = (parameter) => {
    return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(parameter / 100);
}

// FOR FIRST LETTER CAPITALIZE
const capitaizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function roundPrice(price, decimalPlaces = 2) {
    if (isNaN(price)) {
        console.error('Invalid price input. Please provide a valid number.');
        return null;
    }

    const roundedPrice = Number(price).toFixed(decimalPlaces);
    return parseFloat(roundedPrice);
}

export {
    priceFormat,
    capitaizeFirstLetter,
    roundPrice
}