import Banner from "../../Components/Banner";
import Collections from "../../Components/Collections";
import ProductCard from "../../Components/Products/ProductCard";
import Loading from "../../Components/Loading";
import { useSelector } from "react-redux";

const Home = () => {

    const {data,status} =  useSelector((state)=> state.products);

    return(
        <>
            <Banner />
            <Collections />
            <div className="product-wrapper loading-section">
                <div className="container">
                    <div className="site-heading">
                        <h2 className="text-center">Featured Products</h2>
                    </div>
                    <div className="row">
                        {
                            status !== 'idle' 
                            ? 
                            <Loading /> 
                            :
                            data.slice(0,4).map((item, index)=>{
                                return(
                                <ProductCard item={item} index={index} key={index} />
                                )
                            })
                        }              
                    </div>
                </div>
            </div>
        </>

    );
}

export default Home;