import { roundPrice } from '../../utils/functions'
import { useState } from 'react';

const CartList = ({item, index, updateQuantity, handleShow}) => {

    const [quantity, setQuantity] = useState(item.quantity);

    return(
        <div className="cart-repeat" key={index}>
            <ul>
                <li>
                    <div className="cart-img-title">
                        <div className="cart-img">
                            <img src={item.image} alt={item.title} />
                        </div>
                        <div className="cart-title">
                            <h4>{item.title}</h4>
                        </div>
                    </div>
                    <div className="cart-item-price">
                        <h4><span>Price:</span> ${roundPrice(item.price)}</h4>
                    </div>
                    <div className="cart-item-quantity">
                        <div className="cart-input-wrapper">
                            <button onClick={()=>{
                                updateQuantity(item.id,item.quantity - 1)
                                setQuantity(quantity - 1)
                            }}>-</button>
                            <span>{quantity}</span>
                            <button onClick={()=>{
                                updateQuantity(item.id,item.quantity + 1)
                                setQuantity(quantity + 1)
                            }}>+</button>
                        </div>
                    </div>
                    <div className="item-total-trash">
                        <h4><span>Sub Total:</span> ${roundPrice(item.price * item.quantity)}</h4>
                        <div className="trash-item">
                            <svg onClick={()=> handleShow(item)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16"> <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/> </svg> 
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default CartList;