import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllProduct } from '../../features/product/productAPI';

const initialState = {
    data: [],
    filter: [],
    status: "idle",
    error: null
}

export const fetchProducts = createAsyncThunk(
    "product/fetchProducts",
    async () => {
        const response = await getAllProduct();
        return response.data;
    }
);

const productSlice = createSlice({
    name: "producs",
    initialState,

    reducers: {
        getSingleProduct(state,action){

            const product = state.data.find((product)=>product.id === action.payload)
            if(product){
                return product
            } else {
                fetchProducts(action.payload)
            }
        },
        filterProducts: (state, action) => {
            const { category, search } = action.payload;
            if (state.data.length === 0) return;
            state.filter = state.data.filter(
                (product) =>
                product.title.toLowerCase().includes(search.toLowerCase())
                // and check if category is not empty string and product category is not equal to category
                // (category !== "" ? product.category.slug === category : true)
            );
        },
        getSingleProduct: (state, action) => {
            console.log(action.payload);
            const slug = action.payload;
        
            // Check if product is available in state data return product else dispatch fetchProducts action
            const product = state.data.find((product) => product.slug === slug);
            console.log(product);
            if (product) {
                state.singleProduct = product;
            } else {
                console.log("fetching");
                window.location.href = "/404";
            }
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchProducts.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(fetchProducts.fulfilled, (state, action) => {
            state.status = "idle";
            state.data = action.payload;
        })
        .addCase(fetchProducts.rejected, (state, action) => {
            state.status = "idle";
            state.error = action.error?.message || "something went wrong";
        })
    }
});

export const { filterProducts } = productSlice.actions;

export default productSlice.reducer;