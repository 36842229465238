import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "../Pages/Home";
import PublicRoutes from "./PublicRoutes";
import SingleProduct from "../Pages/SingleProduct";
import Shop from "../Pages/Shop";
import ProductCollections from "../Pages/ProductCollection";
import Cart from "../Pages/Cart";
import AboutUs from "../Pages/AboutUs";


const Routes = () => {

    // FOR PUBLIC ROUTES OR PAGES (user can access these pages without any restrictions)
    const ForPublicRoutes = [
        {
            path: "/",
            element: <PublicRoutes />,
            children: [
                {
                path: "",
                element: <Home />,
                },
                {
                    path: "single-product/:id",
                    element: <SingleProduct />,
                },
                {
                    path: "products/category/:id",
                    element: <ProductCollections />,
                },
                {
                    path: "shop",
                    element: <Shop />,
                },
                {
                    path: "cart",
                    element: <Cart />,
                },
                {
                    path: "about-us",
                    element: <AboutUs />,
                }
            ],
        }
    ]

    const router = createBrowserRouter([
        ...ForPublicRoutes,
    ]);

    return(
        <RouterProvider router={router} />
    );
}

export default Routes;