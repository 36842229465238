import { useEffect, useState } from 'react';
import bannerImage01 from '../../assets/images/banner01.jpg';
import Slider from "react-slick";
import {BASE_URL} from '../../utils/Config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { json, useParams } from 'react-router-dom';
import Loading from "../../Components/Loading";

import { addToCart } from '../../features/cart/cartSlice'
import { useDispatch } from 'react-redux';
import CartQuantity from '../../Components/CartQuantity';

const SingleProduct = () => {

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const [singleProductData, setSingleProductData] = useState({});
    const [loading, setLoading] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [slidesData, setSlidesData] = useState([])

    const dispatch = useDispatch();

    const ProductId = useParams();

    const getSingleProductdata = async () => {

        let PRODUCTS_API_URL = `${BASE_URL}products/${ProductId.id}`;

        setLoading(true);
        
        try{
            const response = await axios.get(PRODUCTS_API_URL);
            const res = await response?.data || []
            setSingleProductData(res);
            console.log(res)
            setSlidesData([
                {
                    id: 1,
                    image: res?.image
                  }, {
                    id: 2
                  }, {
                    id: 3
                  }, {
                    id: 4
                  }, {
                    id: 5
                  }, {
                    id: 6
                  },
            ]);
        }
        catch(err){
            toast.error(err);
        }
        finally{
            setLoading(false)
        }

    }

    const {
        id: alias,
        title,
        category,
        description,
        price,
        rating,

    } = singleProductData;
  
    useEffect(() => {
  
        setNav1(slider1);
        setNav2(slider2);
  
    });

    useEffect(() => {
        getSingleProductdata();
    },[]);
  
  
    const settingsMain = {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      asNavFor: '.slider-nav'
    };
  
    const settingsThumbs = {
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      dots: false,
      arrows: false,
      centerMode: true,
      swipeToSlide: true,
      focusOnSelect: true,
      centerPadding: '0'
    };

    return(
        <>
        {
            loading
            ?
            <Loading />
            :
            <div className="single-product-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="product-image-gallery">
                                <Slider {...settingsMain} asNavFor={nav2} ref={slider => (setSlider1(slider))}>
                                    {slidesData.map((slide) =>
                                        <div className="single-image" key={slide.id}>
                                            {
                                                slide?.image ? <img className='platform-img' src={slide?.image} /> : <img src={`https://picsum.photos/800/400?img=${slide.id}`} />
                                            }
                                        </div>
                                    )}
                                </Slider>
                                <div className="gallery-images">
                                    <Slider {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                                        {slidesData.map((slide) =>
                                            <div className="gallery-item" key={slide.id}>
                                                {
                                                    slide?.image ? <img className='platform-img' src={slide?.image} /> : <img src={`https://picsum.photos/800/400?img=${slide.id}`} />
                                                }
                                            </div>
                                        )}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="product-single-content">
                                <div className='product-content'>
                                    <h1>{title || "N/A"}</h1>
                                    <h4><span>Price:</span> ${price || "N/A"}</h4>
                                    <div className='text'>
                                        <h3>Description:</h3>
                                        <p>{description || "N/A"}</p>
                                    </div>
                                    <div className='category-items'>
                                        <h3>Category:</h3>
                                        <span>{category || "N/A"}</span>
                                    </div>
                                </div>
                                {/* <div className='reviews-prd'>
                                    <span><small>Rating:</small> {rating?.rate || "N/A"}</span>
                                    <strong><small>Total Reviews:</small> {rating?.count || "N/A"}</strong>
                                </div> */}
                                <CartQuantity quantity={quantity} setQuantity={setQuantity} />
                                <div className='add-cart-btn'>
                                    <button onClick={()=> dispatch(addToCart({...singleProductData,quantity}))}>Add to cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
            
        </>

    );
}

export default SingleProduct;